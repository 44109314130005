/* #### Generated By: http://www.cufonfonts.com #### */

@charset "UTF-8";
/*!
* Bootstrap Reboot v4.2.1 (https://getbootstrap.com/)
* Copyright 2011-2019 The Bootstrap Authors
* Copyright 2011-2019 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
* Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
*/

@font-face {
    font-family: 'Aristotelica Display Trial Extra Light';
    font-style: normal;
    font-weight: normal;
    /* src: url('Aristotelica Display ExtraLight Trial.eot'); */
    src: url('Aristotelica Display ExtraLight Trial.woff') format('woff');
}

@font-face {
    font-family: 'Aristotelica Display Trial DemiBold';
    font-style: normal;
    font-weight: normal;
    src: url('Aristotelica Display DemiBold Trial.woff') format('woff');
}

@font-face {
    font-family: 'Aristotelica Small Caps Regular';
    font-style: normal;
    font-weight: normal;
    src: url('AristotelicaSmallCaps-Regular.woff') format('woff');
}

html {
    scroll-behavior: smooth;
}

.btn--red-color {
    --color: #F86156
}

.btn--yellow-color {
    --color: #FFCA65
}

.btn--lightgreen-color {
    --color: #C0D650;
}

.btn--blue-color {
    --color: #7D7EE8;
}

.lightgreen--color {
    color: #C0D650;
}

.expo--color {
    color: #4D2B85;
}

.customizer--color {
    color: #fbb5ae;
}

.blue--color {
    color: #7D7EE8;
}

.btn--expo-color {
    --color: #4D2B85;
}

.btn--customizer-color {
    --color: #fbb5ae;
}

testimonials {
    padding: 2rem 0;
    /* margin: 5rem 0; */
    display: block;
    position: relative
}

testimonials .testimonial--author-pic {
    max-width: 180px
}

.testimonial--author-pic {
    max-width: 180px
}

testimonials>.testimonial--bg {
    position: absolute;
    width: 130%;
    height: 130%;
    max-width: 150%;
    left: -18%;
    top: -8rem;
    object-fit: cover
}

testimonials .heading {
    font-size: 20px;
    font-family: Comfortaa, cursive;
    font-weight: 700
}

@media screen and (min-width:768px) {
    testimonials .heading {
        font-size: calc(20px + (1500vw - 11520px)/1151)
    }
}

@media screen and (min-width:1919px) {
    testimonials .heading {
        font-size: 35px
    }
}

.testimonial-carousel .indivudual--testimonial {
    text-align: center;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    padding: 0 20px
}

.centerblock {
    text-align: center;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    padding: 0 20px
}

.testimonial-carousel .testimonial--text {
    margin-top: 2rem;
    position: relative;
    /* font-family: Comfortaa, cursive; */
    font-size: 16px;
    /* transition: all .2s ease; */
    /* font-weight: 700 */
}

.testimonial--text {
    margin-top: 2rem;
    position: relative;
    /* font-family: Comfortaa, cursive; */
    font-size: 16px;
    /* transition: all .2s ease; */
    /* font-weight: 700 */
}

@media screen and (min-width:768px) {
    .testimonial-carousel .testimonial--text {
        font-size: calc(16px + (300vw - 2304px)/1151)
    }
    .testimonial--text {
        font-size: calc(16px + (300vw - 2304px)/1151)
    }
}

@media screen and (min-width:1919px) {
    .testimonial-carousel .testimonial--text {
        font-size: 19px
    }
    .testimonial--text {
        font-size: 19px
    }
}

/* .testimonial-carousel .testimonial--text:before {
    position: absolute;
    top: -40px;
    left: -5px;
    color: #fff;
    content: url(left-quote.2b89e009.png)
} */

/* .testimonial-carousel .testimonial--text:after {
    position: absolute;
    bottom: -40px;
    right: -5px;
    color: #fff;
    content: url(right-quote.3db0d82e.png)
} */

.testimonial-carousel .testimonial--author {
    font-size: 14px;
    transition: all .2s ease;
    position: relative;
    margin-top: 10px;
}

.testimonial--author {
    font-size: 14px;
    /* transition: all .2s ease; */
    position: relative;
    margin-top: 10px;
}

@media screen and (min-width:768px) {
    .testimonial-carousel .testimonial--author {
        font-size: calc(14px + (500vw - 3840px)/1151)
    }
    .testimonial--author {
        font-size: calc(14px + (500vw - 3840px)/1151)
    }
}

@media screen and (min-width:1919px) {
    .testimonial-carousel .testimonial--author {
        font-size: 19px
    }
    .testimonial--author {
        font-size: 19px
    }
}

/* .testimonial-carousel .testimonial--author:before {
    position: absolute;
    content: "-";
    left: -10px
} */

@media screen and (min-width:320px) and (max-width:575px) {
    .testimonial-carousel {
        margin-left: -15px;
        margin-right: -15px
    }
    /* .testimonial-carousel .testimonial--text {
        opacity: .2
    } */
    .testimonial-carousel .testimonial--text:before {
        top: -40px;
        left: -45px;
        transform: scale(.5)
    }
    .testimonial-carousel .testimonial--text:after {
        bottom: -40px;
        right: -45px;
        transform: scale(.5)
    }
    .slick-slide .testimonial--author, .slick-slide .testimonial--text {
        opacity: .2
    }
    .slick-slide.slick-active .testimonial--author, .slick-slide.slick-active .testimonial--text {
        opacity: 1
    }
}

featured-tools {
    padding: 2rem 0;
    display: block
}

featured-tools .heading {
    font-size: 20px;
    font-family: Comfortaa, cursive;
    font-weight: 700
}

@media screen and (min-width:768px) {
    featured-tools .heading {
        font-size: calc(20px + (1500vw - 11520px)/1151)
    }
}

@media screen and (min-width:1919px) {
    featured-tools .heading {
        font-size: 35px
    }
}

featured-tools .featured-tools-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 3rem
}

featured-tools .featured-tools-list .individual-tool {
    flex: 0 0 20%;
    text-align: center
}

featured-tools .featured-tools-list .description {
    font-family: Comfortaa, cursive;
    font-weight: 700
}

circular-info {
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 2rem 0
}

circular-info .layout--experience {
    width: 145%;
    max-width: none;
    position: relative
}

circular-info .layout--experience.left--experiance {
    left: -50%
}

circular-info .layout--experience .circle-svg-group {
    position: relative
}

circular-info .heading {
    font-size: 30px;
    font-weight: 800;
    line-height: 1.1
}

@media screen and (min-width:768px) {
    circular-info .heading {
        font-size: calc(30px + (1500vw - 11520px)/598)
    }
}

@media screen and (min-width:1366px) {
    circular-info .heading {
        font-size: 45px
    }
}

circular-info .heading:first-letter {
    text-transform: uppercase
}

circular-info .title {
    font-size: 16px;
    font-weight: 500
}

@media screen and (min-width:768px) {
    circular-info .title {
        font-size: calc(16px + (400vw - 3072px)/598)
    }
}

@media screen and (min-width:1366px) {
    circular-info .title {
        font-size: 20px
    }
}

circular-info .business-segments {
    display: flex;
    flex-wrap: wrap
}

circular-info .business-segments .segment--name {
    color: #007bff;
    flex: 0 0 33.3333%;
    max-width: 33.3333%
}

circular-info .business-segments .segment--name:hover {
    text-decoration: none;
    color: #437cad
}

@media screen and (min-width:320px) and (max-width:575px) {
    circular-info .layout--experience {
        width: 100%;
        margin-bottom: 3rem;
        max-width: 450px;
        margin-left: auto;
        margin-right: auto
    }
    circular-info .layout--experience.left--experiance {
        left: 0
    }
}

@media screen and (min-width:576px) and (max-width:767px) {
    circular-info .layout--experience {
        width: 200%;
        margin-bottom: 3rem;
        max-width: 450px;
        margin-left: auto;
        margin-right: auto
    }
    circular-info .layout--experience.left--experiance {
        left: -100%
    }
}

/* .our--clients {
    padding: 10px 0
} */

.our--clients {
    /* opacity: 0;
    visibility: hidden;
    transition: all .5s;
    color: #000; */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0 10px;
    justify-content: center;
    align-items: center;
}

.our--clients>* {
    display: none;
}

.our--clients>*:nth-of-type(1) {
    display: block;
}

.our--clients>*:nth-of-type(2) {
    display: block;
}

.our--clients>*:nth-of-type(3) {
    display: block;
}

.our--clients>*:nth-of-type(4) {
    display: block;
}

.our--clients>*:nth-of-type(5) {
    display: block;
}

.our--clients>*:nth-of-type(6) {
    display: block;
}

.our--clients>*:nth-of-type(7) {
    display: block;
}

@media(max-width:600px) {
    .our--clients>*:nth-of-type(4) {
        display: none;
    }
    .our--clients>*:nth-of-type(5) {
        display: none;
    }
    .our--clients>*:nth-of-type(6) {
        display: none;
    }
    .our--clients>*:nth-of-type(7) {
        display: none;
    }
}

.our--clients .client--intro {
    text-align: center;
    /* flex: 0 0 20% */
    margin-left: 1%;
    margin-right: 1%;
    flex: 11.42%;
    -ms-flex: 11.42%;
    max-width: 11.42%;
    list-style-type: none;
}

@media(max-width:600px) {
    .our--clients .client--intro {
        flex: 30.33%;
        -ms-flex: 30.33%;
        max-width: 30.33%;
    }
    .our--clients>*:nth-of-type(4) {
        display: none;
    }
    .our--clients>*:nth-of-type(5) {
        display: none;
    }
    .our--clients>*:nth-of-type(6) {
        display: none;
    }
    .our--clients>*:nth-of-type(7) {
        display: none;
    }
}

.our--clients .client--intro .client--image img {
    width: 100%;
}

/* .our--clients .client--image:hover img {
    filter: grayscale(0);
    opacity: 1
} */

.our--clients img {
    filter: grayscale(100%);
    opacity: .7;
    /* transition: all .5s; */
    max-height: 100%;
    padding: 20px;
}

.our--clients>*:nth-of-type(4) img {
    /* margin-top: 5rem */
    filter: grayscale(0);
    opacity: 1;
    border-radius: 50%;
    /* box-shadow: 0px 0 2px 3px #FFCA65; */
    padding: 1px;
}

@media(max-width:600px) {
    .our--clients>*:nth-of-type(2) img {
        /* margin-top: 5rem */
        filter: grayscale(0);
        opacity: 1;
        border-radius: 50%;
        /* box-shadow: 0px 0 2px 3px #FFCA65; */
        padding: 5px;
    }
}

/* @media(max-width:600px) {
    .our--clients img {
        padding: 10px;
    }
} */

/* .our--clients .client--name {
    font-size: 15px;
    font-family: Comfortaa, cursive
} */

/* .our--clients .clients--more {
    font-weight: 800;
    font-size: 25px;
    margin-bottom: 0
} */

.our--clients2 {
    /* opacity: 0;
    visibility: hidden;
    transition: all .5s;
    color: #000; */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0 10px;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    width: 35%;
}

.our--clients2 .client--intro2 {
    text-align: center;
    /* flex: 0 0 20% */
    margin-left: 1%;
    margin-right: 1%;
    flex: 14.45%;
    -ms-flex: 30%;
    max-width: 30%;
    list-style-type: none;
}

@media(max-width:600px) {
    .our--clients2 {
        width: 75%;
    }
    .our--clients2 .client--intro2 {
        flex: 30.33%;
        -ms-flex: 30.33%;
        max-width: 30.33%;
    }
    .our--clients2>* {
        display: none;
    }
    .our--clients2>*:nth-of-type(1) {
        display: block;
    }
    .our--clients2>*:nth-of-type(2) {
        display: block;
    }
    .our--clients2>*:nth-of-type(3) {
        display: block;
    }
}

.our--clients2 .client--intro2 .client--image img {
    width: 100%;
}

.our--clients2>*:nth-of-type(2) img {
    /* margin-top: 5rem */
    filter: grayscale(0);
    opacity: 1;
    border-radius: 50%;
    /* box-shadow: 0px 0 2px 3px #FFCA65; */
    padding: 1px;
}

.our--clients2 img {
    filter: grayscale(100%);
    opacity: .7;
    /* transition: all .5s; */
    max-height: 100%;
    padding: 20px;
}

@media(max-width:600px) {
    .our--clients2>*:nth-of-type(2) img {
        /* margin-top: 5rem */
        filter: grayscale(0);
        opacity: 1;
        border-radius: 50%;
        /* box-shadow: 0px 0 2px 3px #FFCA65; */
        padding: 5px;
    }
}

.our--clients4 {
    /* opacity: 0;
    visibility: hidden;
    transition: all .5s;
    color: #000; */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0 10px;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    width: 59.74%;
}

.our--clients4 .client--intro4 {
    text-align: center;
    /* flex: 0 0 20% */
    margin-left: 1%;
    margin-right: 1%;
    flex: 20%;
    -ms-flex: 20%;
    max-width: 20%;
    /* width: 12.935%; */
    list-style-type: none;
}

@media(max-width:600px) {
    .our--clients4 {
        width: 100%;
    }
    .our--clients4 .client--intro4 {
        flex: 30.33%;
        -ms-flex: 30.33%;
        max-width: 30.33%;
    }
    .our--clients4>* {
        display: none;
    }
    .our--clients4>*:nth-of-type(1) {
        display: block;
    }
    .our--clients4>*:nth-of-type(2) {
        display: block;
    }
    .our--clients4>*:nth-of-type(3) {
        display: block;
    }
}

.our--clients4 .client--intro4 .client--image img {
    width: 100%;
}

.our--clients4>*:nth-of-type(2) img {
    /* margin-top: 5rem */
    filter: grayscale(0);
    opacity: 1;
    border-radius: 50%;
    /* box-shadow: 0px 0 2px 3px #FFCA65; */
    padding: 1px;
}

.our--clients4 img {
    filter: grayscale(100%);
    opacity: .7;
    /* transition: all .5s; */
    max-height: 100%;
    padding: 20px;
}

@media(max-width:600px) {
    .our--clients4>*:nth-of-type(2) img {
        /* margin-top: 5rem */
        filter: grayscale(0);
        opacity: 1;
        border-radius: 50%;
        /* box-shadow: 0px 0 2px 3px #FFCA65; */
        padding: 5px;
    }
}

.our--clients6 {
    /* opacity: 0;
    visibility: hidden;
    transition: all .5s;
    color: #000; */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0 10px;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    width: 86.58%;
}

.our--clients6 .client--intro6 {
    text-align: center;
    /* flex: 0 0 20% */
    margin-left: 1%;
    margin-right: 1%;
    flex: 13.43%;
    -ms-flex: 13.43%;
    max-width: 13.43%;
    /* width: 12.43%; */
    list-style-type: none;
}

.extraspace {
    width: 13.42%;
}

@media(max-width:600px) {
    .our--clients6 {
        width: 100%;
    }
    .our--clients6 .client--intro6 {
        flex: 30.33%;
        -ms-flex: 30.33%;
        max-width: 30.33%;
    }
    .our--clients6>* {
        display: none;
    }
    .our--clients6>*:nth-of-type(1) {
        display: block;
    }
    .our--clients6>*:nth-of-type(2) {
        display: block;
    }
    .our--clients6>*:nth-of-type(3) {
        display: block;
    }
}

.our--clients6 .client--intro4 .client--image img {
    width: 100%;
}

.our--clients6>*:nth-of-type(4) img {
    /* margin-top: 5rem */
    filter: grayscale(0);
    opacity: 1;
    border-radius: 50%;
    /* box-shadow: 0px 0 2px 3px #FFCA65; */
    padding: 1px;
}

.our--clients6 img {
    filter: grayscale(100%);
    opacity: .7;
    /* transition: all .5s; */
    max-height: 100%;
    padding: 20px;
}

@media(max-width:600px) {
    .our--clients6>*:nth-of-type(2) img {
        /* margin-top: 5rem */
        filter: grayscale(0);
        opacity: 1;
        border-radius: 50%;
        /* box-shadow: 0px 0 2px 3px #FFCA65; */
        padding: 5px;
    }
}

footer {
    padding: 30px 0;
    /* background-color: #f9f9f9; */
    font-family: Comfortaa, cursive;
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    padding-top: 120px;
}

@media(max-width:600px) {
    footer {
        padding-top: 60px;
    }
}

footer .footer--logo-container {
    padding: 30px;
    margin: 0 auto;
    max-width: 150px
}

footer .footer--heading {
    color: white;
    font-weight: 500;
    position: relative;
    padding-bottom: 10px;
    font-size: 14px;
    margin-top: 20px;
    font-weight: 900;
    display: flex;
    justify-content: space-between
}

footer .footer--heading:before {
    position: absolute;
    content: "";
    left: 0;
    right: 50px;
    bottom: 0;
    height: 1px;
    background-color: rgba(89, 89, 89, .3)
}

footer .footer--details {
    padding-top: 10px
}

footer .footer--details .details--text {
    font-size: 14px;
    color: white;
    font-weight: 600
}

footer .footer--details .details--text a {
    color: white !important;
}

footer .footer--details .phone-number {
    position: relative;
    padding-left: 25px
}

footer .footer--details .phone-number a {
    color: white !important;
}

footer .footer--details .phone-number:before {
    position: absolute;
    content: "C";
    font-family: business-mlz;
    left: 0;
    top: 0
}

footer .footer--details .email-id {
    position: relative;
    padding-left: 25px
}

footer .footer--details .email-id:before {
    position: absolute;
    content: "M";
    font-family: business-mlz;
    left: 0;
    top: 0
}

footer .footer--address {
    line-height: 2;
    position: relative;
    padding-left: 25px
}

footer .footer--address:before {
    position: absolute;
    content: "A";
    font-family: business-mlz;
    left: 0;
    top: 0
}

footer .social-links {
    display: flex;
    margin-top: 20px
}

footer .social-links a {
    width: 50px;
    height: 50px;
    margin-right: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #36cca5; */
    border-radius: 50%;
    position: relative;
    z-index: 1;
    transition: all .5s;
    color: #f9f9f9
}

/* footer .social-links a:hover:before {
  left: -5px
}

footer .social-links a:hover:after {
  bottom: 5px
} */

/* footer .social-links a:before {
  left: 4px;
  top: -6px;
  background-color: rgba(54, 204, 165, .4)
}

footer .social-links a:after,
footer .social-links a:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: -1;
  transition: all .5s
}

footer .social-links a:after {
  left: 7px;
  bottom: -5px;
  background-color: rgba(54, 204, 165, .8)
} */

footer .social-links i {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1
}

@media screen and (min-width:320px) and (max-width:767px) {
    footer .footer--logo-container {
        max-width: 30px;
        padding: 0 0 30px
    }
    footer .footer--heading:before {
        right: 0
    }
}

.botttom-footer {
    padding: 30px 15px;
    background-color: #e8e8e8;
    font-family: Comfortaa, cursive
}

.botttom-footer p {
    margin-bottom: 0;
    font-size: 14px;
    color: #414141;
    font-weight: 600
}

/*start products animation css */

.circle-container {
    position: relative;
    width: 30em;
    height: 30em;
    border-radius: 50%;
    padding: 0;
    list-style: none;
    margin: 5em auto 0;
}

.circle-container>* {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -3em;
    width: 5.3em;
    height: 5.3em;
    margin-left: 0;
    margin-right: 0;
}

.circle-container>*:nth-of-type(1) {
    /* top: 20%;
      left: 20%; */
    top: 8em;
    left: 8em;
    width: 20em !important;
    height: 20em !important;
}

/* five product code */

/* .circle-container>*:nth-of-type(2) {
    -webkit-transform: rotate(90deg) translate(15em) rotate(-90deg);
    transform: rotate(90deg) translate(15em) rotate(-90deg);
}

.circle-container>*:nth-of-type(3) {
    -webkit-transform: rotate(135deg) translate(15em) rotate(-135deg);
    transform: rotate(135deg) translate(15em) rotate(-135deg);
}

.circle-container>*:nth-of-type(4) {
    -webkit-transform: rotate(180deg) translate(15em) rotate(-180deg);
    transform: rotate(180deg) translate(15em) rotate(-180deg);
}

.circle-container>*:nth-of-type(5) {
    -webkit-transform: rotate(225deg) translate(15em) rotate(-225deg);
    transform: rotate(225deg) translate(15em) rotate(-225deg);
}

.circle-container>*:nth-of-type(6) {
    -webkit-transform: rotate(270deg) translate(15em) rotate(-270deg);
    transform: rotate(270deg) translate(15em) rotate(-270deg);
} */

/* seven product code */

/* .circle-container>*:nth-of-type(2) {
    -webkit-transform: rotate(90deg) translate(15em) rotate(-90deg);
    transform: rotate(90deg) translate(15em) rotate(-90deg);
}

.circle-container>*:nth-of-type(3) {
    -webkit-transform: rotate(120deg) translate(15em) rotate(-120deg);
    transform: rotate(120deg) translate(15em) rotate(-120deg);
}

.circle-container>*:nth-of-type(4) {
    -webkit-transform: rotate(150deg) translate(15em) rotate(-150deg);
    transform: rotate(150deg) translate(15em) rotate(-150deg);
}

.circle-container>*:nth-of-type(5) {
    -webkit-transform: rotate(180deg) translate(15em) rotate(-180deg);
    transform: rotate(180deg) translate(15em) rotate(-180deg);
}

.circle-container>*:nth-of-type(6) {
    -webkit-transform: rotate(210deg) translate(15em) rotate(-210deg);
    transform: rotate(210deg) translate(15em) rotate(-210deg);
}

.circle-container>*:nth-of-type(7) {
    -webkit-transform: rotate(240deg) translate(15em) rotate(-240deg);
    transform: rotate(240deg) translate(15em) rotate(-240deg);
}

.circle-container>*:nth-of-type(8) {
    -webkit-transform: rotate(270deg) translate(15em) rotate(-270deg);
    transform: rotate(270deg) translate(15em) rotate(-270deg);
} */

/* nine product code */

.circle-container>*:nth-of-type(2) {
    -webkit-transform: rotate(90deg) translate(15em) rotate(-90deg);
    transform: rotate(90deg) translate(15em) rotate(-90deg);
}

.circle-container>*:nth-of-type(3) {
    -webkit-transform: rotate(112.5deg) translate(15em) rotate(-112.5deg);
    transform: rotate(112.5deg) translate(15em) rotate(-112.5deg);
}

.circle-container>*:nth-of-type(4) {
    -webkit-transform: rotate(135deg) translate(15em) rotate(-135deg);
    transform: rotate(135deg) translate(15em) rotate(-135deg);
}

.circle-container>*:nth-of-type(5) {
    -webkit-transform: rotate(157.5deg) translate(15em) rotate(-157.5deg);
    transform: rotate(157.5deg) translate(15em) rotate(-157.5deg);
}

.circle-container>*:nth-of-type(6) {
    -webkit-transform: rotate(180deg) translate(15em) rotate(-180deg);
    transform: rotate(180deg) translate(15em) rotate(-180deg);
}

.circle-container>*:nth-of-type(7) {
    -webkit-transform: rotate(202.5deg) translate(15em) rotate(-202.5deg);
    transform: rotate(202.5deg) translate(15em) rotate(-202.5deg);
}

.circle-container>*:nth-of-type(8) {
    -webkit-transform: rotate(225deg) translate(15em) rotate(-225deg);
    transform: rotate(225deg) translate(15em) rotate(-225deg);
}

.circle-container>*:nth-of-type(9) {
    -webkit-transform: rotate(247.5deg) translate(15em) rotate(-247.5deg);
    transform: rotate(247.5deg) translate(15em) rotate(-247.5deg);
}

.circle-container>*:nth-of-type(10) {
    -webkit-transform: rotate(270deg) translate(15em) rotate(-270deg);
    transform: rotate(270deg) translate(15em) rotate(-270deg);
}

.circle-container>*:nth-of-type(1) img {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
    /* opacity: 1; */
}

.circle-container>*:nth-of-type(6) img {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
    /* opacity: 1; */
}

.circle-container>*:nth-of-type(6) .opacity-img {
    /* -webkit-filter: grayscale(0);
    filter: grayscale(0); */
    opacity: 1;
}

.circle-container>*:nth-of-type(6) .opacityunite-img {
    /* -webkit-filter: grayscale(0);
    filter: grayscale(0); */
    opacity: 1;
}

.rot-pos-1 {
    animation: move-pos-1 0.5s 1 linear;
}

.rot-pos-2 {
    animation: move-pos-2 0.5s 1 linear;
}

.rot-pos-3 {
    animation: move-pos-3 0.5s 1 linear;
}

.rot-pos-4 {
    animation: move-pos-4 0.5s 1 linear;
}

.rot-pos-5 {
    animation: move-pos-5 0.5s 1 linear;
}

.rot-pos-6 {
    animation: move-pos-6 0.5s 1 linear;
}

.rot-pos-7 {
    animation: move-pos-7 0.5s 1 linear;
}

.rot-pos-8 {
    animation: move-pos-8 0.5s 1 linear;
}

.rot-pos-9 {
    animation: move-pos-9 0.5s 1 linear;
}

.slide-in {
    animation: slideIn 0.5s forwards;
}

.rotate {
    animation: rotation 0.5s 1 linear;
}

@keyframes move-pos-1 {
    from {
        transform: rotate(90deg) translate(15em) rotate(-90deg);
    }
    to {
        transform: rotate(112.5deg) translate(15em) rotate(-112.5deg);
    }
}

@keyframes move-pos-2 {
    from {
        transform: rotate(112.5deg) translate(15em) rotate(-112.5deg);
    }
    to {
        transform: rotate(135deg) translate(15em) rotate(-135deg);
    }
}

@keyframes move-pos-3 {
    from {
        transform: rotate(135deg) translate(15em) rotate(-135deg);
    }
    to {
        transform: rotate(157.5deg) translate(15em) rotate(-157.5deg);
    }
}

@keyframes move-pos-4 {
    from {
        transform: rotate(157.5deg) translate(15em) rotate(-157.5deg);
    }
    to {
        transform: rotate(180deg) translate(15em) rotate(-180deg);
    }
}

@keyframes move-pos-5 {
    from {
        transform: rotate(180deg) translate(15em) rotate(-180deg);
    }
    to {
        transform: rotate(202.5deg) translate(15em) rotate(-202.5deg);
    }
}

@keyframes move-pos-6 {
    from {
        transform: rotate(202.5deg) translate(15em) rotate(-202.5deg);
    }
    to {
        transform: rotate(225deg) translate(15em) rotate(-225deg);
    }
}

@keyframes move-pos-7 {
    from {
        transform: rotate(225deg) translate(15em) rotate(-225deg);
    }
    to {
        transform: rotate(247.5deg) translate(15em) rotate(-247.5deg);
    }
}

@keyframes move-pos-8 {
    from {
        transform: rotate(247.5deg) translate(15em) rotate(-247.5deg);
    }
    to {
        transform: rotate(270deg) translate(15em) rotate(-270deg);
    }
}

@keyframes move-pos-9 {
    from {
        transform: rotate(270deg) translate(15em) rotate(-270deg);
    }
    to {
        transform: rotate(292.5deg) translate(15em) rotate(-292.5deg);
    }
}

@keyframes slideIn {
    0% {
        transform: translateX(-900px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes rotation {
    0% {
        transform: translateX(1800px);
    }
    100% {
        transform: translateX(0);
    }
}

.opacity-img {
    opacity: 0.3;
}

.opacityunite-img {
    opacity: 0.7;
}

.opacity-img:hover {
    opacity: 1;
}

.opacityunite-img:hover {
    opacity: 1;
}

.circle-container img {
    display: block;
    max-width: 100%;
    border-radius: 50%;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    /* opacity: .7; */
    /* border: solid 5px tomato; */
    /* -webkit-transition: .15s; */
    /* transition: .15s; */
}

/* .circle-container .opacity {
    opacity: 0.4;
} */

.circle-container img:hover {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
    /* opacity: 1; */
}

/*end products animation css */

.mobdisplaymenu {
    display: none;
}

.transparentbtn {
    background: transparent !important;
    border: 0 !important;
    outline: none !important;
}

@media(max-width:900px) {
    .mobdisplaymenu {
        display: flex;
    }
}

.featureicon {
    width: 240px;
}

@media(max-width:900px) {
    .featureicon {
        width: 150px;
    }
}

.featuretex {
    margin-top: 5px;
}

@media(max-width:600px) {
    .featuretex {
        margin-top: 10px;
    }
}

.boxshadow {
    position: fixed;
    height: 70px;
    width: 100%;
    /* box-shadow: inset 600px 600px 6px 0px rgba(54, 204, 165, 0.25);
    -webkit-box-shadow: inset 600px 600px 6px 0px rgba(54, 204, 165, 0.6); */
    z-index: 1;
    background: #36cca5;
}

/* @media(max-width:900px) {
    .boxshadow {
        height: 8%;
    }
} */

@media(max-width:600px) {
    .boxshadow {
        height: 60px;
    }
}

.flexcontainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    position: absolute;
    z-index: 100;
    color: white;
    list-style-type: none;
    font-size: 15px;
    justify-content: space-between;
    padding: 0;
    font-weight: bold;
    top: 16px;
    /* right: 10px; */
    font-family: Comfortaa, cursive;
    padding-left: 16px;
    padding-right: 16px;
}

/* @media(max-width:900px) {
    .flexcontainer {
        font-size: 8px;
    }


} */

.bottomflexcontainer li a {
    color: white !important;
}

.flexcontainer li a {
    color: white !important;
}

.colonav {
    color: black;
}

.flexitem1 {
    align-self: center;
    margin-right: 20px;
}

.flexitem2 {
    align-self: center;
    margin-right: 20px;
}

.flexitem3 {
    align-self: center;
    margin-right: 20px;
}

.flexitem4 {
    align-self: center;
    margin-right: 20px;
}

.flexitem5 {
    align-self: center;
    margin-right: 20px;
}

.overflow {
    overflow-x: hidden;
}

.melzologo {
    width: 140px;
}

@media(max-width:600px) {
    .melzologo {
        width: 100px;
    }
    /* .paddingfooter {
        padding-top: 100px;
    } */
}

.dotactive, .dotactivecreator, .dotactiveReal, .dotactiveS, .dotredactive, .dotgreenactive, .dotblueactive, .dotactiveMyBlue, .dotactiveFive, .dotactivetestimonials, .dotactiveFiveHelth, .dotyellowactive, .dotactivSeven, .dotactivSeven1, .dotacheivement, .dotacheivementmob, .dot:hover {
    /* background-color: #36cca5; */
    opacity: 1;
}

.dotactivetest {
    opacity: 1;
}

.dotacheivement {
    opacity: 1;
}

.dotacheivementmob {
    opacity: 1;
}

.dotactiveclients {
    opacity: 1;
}

.dotactivetestcomp {
    opacity: 1;
}

/* -----------------SWIPE----------------- */

#swipe {
    /* background: silver; */
    background: '';
    /* height: 100%; */
    /* text-align: center; */
    /* font: 30px/300px Helvetica, Arial, sans-serif; */
}

#swipeFour {
    background: '';
}

#swipeChoose {
    /* background: silver; */
    background: '';
    /* height: 100%; */
    /* text-align: center; */
    /* font: 30px/300px Helvetica, Arial, sans-serif; */
}

.size-text {
    text-align: center;
    font-size: 30px;
    margin: 3rem 0;
    font-weight: bold;
}

@media(max-width:600px) {
    .size-text {
        font-size: 15px;
        margin: 1rem 0;
    }
}

.lineafter {
    font-size: 40px;
    position: relative;
}

.lineafter:after {
    content: "";
    display: inline-block;
    /* height: 0.5em; */
    vertical-align: top;
    width: 150%;
    margin-right: -100%;
    margin-top: 16px;
    transform: translateX(-10px);
    position: absolute;
}

@media(max-width:600px) {
    .lineafter {
        font-size: 20px;
        text-align: center;
    }
    .lineafter:after {
        margin-top: 7px;
        width: 100%;
    }
    .size {
        font-size: 15px;
    }
}

.extraaftermove:after {
    transform: translateX(-5px) !important;
}

.extrabeforemove:before {
    transform: translateX(-93%) !important;
}

@media(max-width:600px) {
    .extrabeforemove:before {
        transform: translateX(-95%) !important;
    }
}

.centertext {
    text-align: center;
}

.titlebefore {
    font-size: 40px;
    position: relative;
}

.titlebefore:before {
    content: "";
    display: inline-block;
    /* height: 0.5em; */
    vertical-align: top;
    width: 150%;
    margin-right: -150%;
    margin-top: 16px;
    transform: translateX(-98%);
}

.extramove:before {
    transform: translateX(-99%) !important;
}

.extramovetitle:before {
    transform: translateX(-98%) !important;
}

.extramoveafter:after {
    transform: translateX(-15px) !important;
}

.movelineafter:after {
    transform: translateX(-22px) !important;
}

.tabletitlesize {
    font-size: 40px;
}

@media(max-width:600px) {
    .titlebefore {
        font-size: 20px;
        text-align: center;
    }
    .titlebefore:before {
        margin-top: 7px;
        transform: translateX(-95%);
        width: 100%;
        margin-right: -100%;
    }
    .extramove:before {
        transform: translateX(-98%) !important;
    }
    .tabletitlesize {
        font-size: 20px;
    }
    .movelineafter:after {
        transform: translateX(-4px) !important;
    }
    .extramovetitle:before {
        transform: translateX(-95%) !important;
    }
}

.hrmargin1 {
    margin-bottom: 50px;
    color: white;
    border: 1px solid white;
}

.hrmargin2 {
    margin-top: 30px;
    color: white;
    border: 1px solid white;
}

.topmargin {
    margin-top: 3rem;
}

.quotetext {
    line-height: 1.1;
}

.btn-mz:active {
    transform: scale(0.8);
}

@media(max-width:600px) {
    .hrmargin1 {
        margin-bottom: 20px;
    }
    .hrmargin2 {
        margin-top: 0px;
    }
    .topmargin {
        margin-top: 3rem;
    }
    .quotetext {
        font-size: 20px;
    }
}

.flex-table {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    overflow-x: auto;
}

@media(min-width:601px) {
    .flex-table {
        display: flex;
        justify-content: center;
        align-items: center;
        /* overflow-x: auto; */
    }
}

@media(max-width:1100px) {
    .heightdescription {
        font-size: 15px;
    }
}

.paddingtd {
    padding: 10px;
}

.industrylogo {
    width: 300px;
}

.borderbottom {
    border-bottom: 3px solid white;
}

@media(max-width:600px) {
    .industrylogo {
        width: 200px;
    }
}

.melzotitle {
    font-weight: bold;
    font-size: 30px;
    /* font-family: 'Aristotelica Display Trial DemiBold'; */
    line-height: 0.7;
}

.melzoheading {
    font-size: 50px;
    /* font-family: 'Aristotelica Display Trial DemiBold'; */
    line-height: 1;
    margin-bottom: 25px;
}

.colortext {
    color: black;
}

.margbomtextsector {
    margin-bottom: 70px;
    margin-top: 35px;
}

@media(max-width:600px) {
    .margbomtextsector {
        margin-bottom: 40px;
        margin-top: 35px;
    }
}

.headingsize {
    font-size: 30px;
    font-weight: 800;
    line-height: 1.1
}

.lineheight {
    line-height: 1.1;
}

@media(max-width:600px) {
    .melzotitle {
        font-size: 25px;
    }
    .melzoheading {
        font-size: 35px;
    }
    .marginbottomimage {
        margin-bottom: 3rem;
    }
    .headingsize {
        font-size: 25px;
    }
}

.sizedesc {
    font-size: 21px;
    color: black;
    margin-top: 25px;
}

.colordescription {
    color: black;
    font-size: 21px;
}

@media(max-width:600px) {
    .sizedesc {
        font-size: 15px;
    }
    .colordescription {
        font-size: 15px;
    }
}

.calendar {
    position: fixed !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    width: 100%;
    height: 100%;
    display: none;
}

.iframeflex {
    display: flex;
}

@media(max-width:600px) {
    .featurecenterimg {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        align-items: center;
    }
    .calendar {
        height: 60%;
    }
}

.widthfeatureimg {
    width: 100px;
    margin-bottom: 15px;
}

.marginbottomrow {
    margin-bottom: 20px;
}

.individual--circle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

div.laptop-wrapper {
    position: relative;
    padding-top: 10px;
    padding-bottom: 67.5%;
    height: 0;
    width: 100%;
}

div.laptop-wrapper iframe {
    box-sizing: border-box;
    background: url(https://i.stack.imgur.com/zZNgk.png) center center no-repeat;
    background-size: contain;
    padding: 11.9% 15.5% 14.8%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media(max-width:600px) {
    .center-buttonmob {
        display: flex;
        justify-content: center;
    }
}

.margintoptext {
    margin-top: 30px;
    margin-bottom: 0px;
}

.clientbtnposition {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
}

body::-webkit-scrollbar {
    width: 6px;
}

body::-webkit-scrollbar-track {
    background: transparent;
}

body::-webkit-scrollbar-thumb {
    background-color: #36cca5;
    border-radius: 20px;
    border: 3px solid transparent;
}

.widthclientname {
    width: 100%;
    margin: 30px auto;
    margin-bottom: 0px;
}

.rightsidemenu.openbottommenu1 {
    -webkit-animation: openmenu1 1s;
    -moz-animation: openmenu1 1s;
    animation: openmenu1 1s;
    transform: translateX(0px);
}

@keyframes openmenu1 {
    0% {
        transform: translateX(300px);
    }
    100% {
        transform: translateX(0px);
    }
}

.animationclass {
    animation: lefttoright 0.5s forwards;
}

@keyframes lefttoright {
    0% {
        transform: translateX(900px);
    }
    100% {
        transform: translateX(0);
    }
}

.testimonials-container {
    padding: 0;
    /* height: 650px; */
}

.testimonials-container>* {
    display: none;
}

.testimonials-container>*:nth-of-type(1) {
    display: block;
}

.testimonials-container>*:nth-of-type(2) {
    display: block;
}

.testimonials-container>*:nth-of-type(3) {
    display: block;
}

@media(max-width:600px) {
    .testimonials-container>*:nth-of-type(3) {
        display: none;
    }
    .testimonials-container>*:nth-of-type(1) {
        display: none;
    }
}

.testimonials-container img {
    opacity: .5
}

.testimonials-container>*:nth-of-type(2) img {
    opacity: 1;
}

.testimonials-container .testimonial--author {
    opacity: .5
}

.testimonials-container>*:nth-of-type(2) .testimonial--author {
    opacity: 1;
}

.testimonials-container .testimonial--text {
    color: #e6e6e6;
}

.testimonials-container>*:nth-of-type(2) .testimonial--text {
    color: black;
}

.testimonialtext {
    color: #e6e6e6;
}

.lightgreen {
    padding: 1px;
}

.dotclass {
    display: flex;
    list-style-type: none;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.flexcontainermob {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    padding-top: 15px;
}

.flexright {
    display: flex;
    flex-direction: row;
}

.menuposition {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
}

.flexmenu {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0;
    font-weight: bold;
    top: 16px;
    /* right: 10px; */
    font-family: Comfortaa, cursive;
    list-style-type: none;
    padding: 20px;
    width: 100%;
}

.menubg {
    width: 80%;
    height: 100%;
    /* right: -20%; */
    position: fixed;
    background: white;
    z-index: 3;
    display: none;
}

.close {
    color: #000;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.flexmenulist {
    margin-top: 20px;
    color: #36cca5;
}

.openmenuanimation {
    animation: rigthtoleftmenu 0.5s forwards;
}

@keyframes rigthtoleftmenu {
    0% {
        right: -150%;
    }
    100% {
        right: 0%;
    }
}

.closmenuanimation {
    animation: lefttorightmenu 1s forwards;
}

@keyframes lefttorightmenu {
    0% {
        right: 0%;
    }
    100% {
        right: -150%;
    }
}

.overlaymenu {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
}

.widthmelzoicon {
    width: 30px;
}

.underlinegreen {
    text-decoration: underline;
    color: #36cca5;
}

.mobview {
    display: none;
}

.compview {
    display: block;
}

@media(max-width:900px) {
    .mobview {
        display: block;
    }
    .compview {
        display: none;
    }
}

.demoposition {
    min-width: 150px;
    max-width: 150px;
    height: 80px;
    /* background: #866ec7; */
    color: white;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    float: right;
    position: fixed;
    right: 0%;
    bottom: 7em;
    z-index: 1;
    display: flex;
    margin-right: -150px;
    cursor: pointer;
}

.demoBtn {
    display: block;
    position: absolute;
    /* bottom: 10em;
    right: 0px; */
    z-index: 1;
    font-size: 18px;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 5px;
    width: 70px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    margin-left: -70px;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.demoposition.activedemo {
    margin-right: 0px;
}

.demoposition.activedemo .demoBtn {
    margin-left: -69px;
}

.demotextflex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.demotextmargin {
    margin-bottom: 0px;
}

.underlinewhite {
    text-decoration: underline;
    color: #fff;
    cursor: pointer;
    font-family: Comfortaa, cursive;
}

#whatsappBtn {
    display: block;
    position: fixed;
    bottom: 1em;
    right: 0px;
    z-index: 1;
    font-size: 18px;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 5px;
    border-radius: 4px;
    width: 70px;
    /* background: #36cca5; */
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    cursor: pointer;
}

.widthwhatsapp {
    width: 50px;
}

#myBtn {
    display: block;
    position: fixed;
    bottom: 1em;
    left: 10px;
    z-index: 1;
    font-size: 18px;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 5px;
    padding-bottom: 8px;
    border-radius: 4px;
    width: 40px;
    -moz-transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}

.achievement-container {
    padding: 0;
    /* height: 650px; */
}

.achievement-container>* {
    display: none;
}

.achievement-container>*:nth-of-type(1) {
    display: block;
}

.achievement-container>*:nth-of-type(2) {
    display: block;
}

.achievement-container>*:nth-of-type(3) {
    display: block;
}

.achievement-container img {
    opacity: .5
}

.achievement-container>*:nth-of-type(2) img {
    opacity: 1;
}

.achievement-container .testimonial--author {
    opacity: .5
}

.achievement-container>*:nth-of-type(2) .testimonial--author {
    opacity: 1;
}

.achievement-container .testimonial--text {
    color: #e6e6e6;
}

.achievement-container>*:nth-of-type(2) .testimonial--text {
    color: black;
}

/* start overlay form design css */

/* 
.container {
    background-color: #9191e9;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
} */

.button {
    text-decoration: none;
    font-size: 0.875rem;
    font-weight: 300;
    text-transform: uppercase;
    display: inline-block;
    border-radius: 1.5rem;
    background-color: #fff;
    color: #9191e9;
    padding: 1rem 2rem;
}

.popupform {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .80);
    z-index: 1100;
    /* visibility: hidden; */
    /* opacity: 0; */
    overflow: hidden;
    /* transition: 0.64s ease-in-out; */
}

.popup-inner {
    position: relative;
    /* bottom: -100vw; */
    /* right: -100vh; */
    display: flex;
    align-items: center;
    max-width: 900px;
    max-height: 600px;
    width: 100%;
    height: 90%;
    background-color: #fff;
    /* border: 2px solid #7D7EE8; */
    border-radius: 5px;
    position: fixed;
    top: 50%;
    transform: translate(0px, -50%);
    /* transform: rotate(32deg); */
    /* transition: 0.64s ease-in-out; */
}

@media(max-width:600px) {
    .popup-inner {
        flex-direction: column;
        width: 80%;
        height: 92%;
        position: fixed;
        top: 50%;
        transform: translate(0px, -50%);
    }
}

.popupphoto {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: hidden;
}

@media(max-width:600px) {
    .popupphoto {
        width: 100%;
    }
}

.popupphoto img {
    width: auto;
    position: relative;
    left: -12%;
    /* height: 100%; */
}

@media(max-width:600px) {
    .popupphoto img {
        width: auto;
        position: relative;
        left: 0%;
        top: -10%;
        /* height: 100%; */
    }
}

.popuptext {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    height: 100%;
    padding: 1rem;
    padding-left: 0em;
}

@media(max-width:600px) {
    .popuptext {
        width: 100%;
        padding: 1rem;
    }
}

.popuptext h3 {
    font-size: 1.1rem;
    /* font-weight: 600; */
    /* margin-bottom: 2rem; */
    text-transform: uppercase;
    line-height: 1.1;
}

@media(max-width:600px) {
    .popuptext h3 {
        font-size: 0.5rem;
        margin-bottom: 0px;
    }
    .popuptext p {
        font-size: 0.5rem;
    }
}

/* .popuptext p {
    font-size: 0.875rem;
    color: #686868;
    line-height: 1.5;
} */

.popup:target {
    visibility: visible;
    opacity: 1;
}

.popup:target .popup-inner {
    bottom: 0;
    right: 0;
    transform: rotate(0);
}

.popup__close {
    position: absolute;
    right: -1rem;
    top: -1rem;
    width: 3rem;
    height: 3rem;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 100%;
    background-color: white;
    z-index: 4;
    /* color: #7D7EE8; */
    /* border: 2px solid #7D7EE8; */
    line-height: 3rem;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
}

/* .popup__close :hover {
    color: #7D7EE8 !important;
} */

.blueinputclass {
    border: 0;
    padding: 5px 5px !important;
    width: 100%;
    font-size: 16px;
    resize: none;
    font-family: Comfortaa, cursive;
    background: transparent;
    box-shadow: none;
    margin-bottom: 10px;
}

@media(max-width: 600px) {
    .blueinputclass {
        margin-bottom: 0px;
    }
}

input.blueinputclass ::-moz-placeholder {
    font-size: 16px;
    font-family: Comfortaa, cursive
}

input.blueinputclass :-ms-input-placeholder {
    font-size: 16px;
    font-family: Comfortaa, cursive
}

input.blueinputclass::-webkit-input-placeholder {
    font-size: 16px;
    font-family: Comfortaa, cursive
}

input.blueinputclass::placeholder {
    font-size: 16px;
    font-family: Comfortaa, cursive
}

@media(max-width: 600px) {
    input.blueinputclass::placeholder {
        font-size: 10px;
    }
}

.custom-selectform {
    position: relative;
    font-family: Comfortaa, cursive;
}

.custom-selectform select {
    display: none;
}

.select-selectedform {
    background-color: transparent;
}

/*style the arrow inside the select element:*/

.select-selectedform:after {
    position: absolute;
    content: "";
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    /* border-color: #7D7EE8 transparent transparent transparent; */
}

/*point the arrow upwards when the select box is open (active):*/

.select-selectedform.select-arrow-active:after {
    border-color: transparent transparent #fff transparent;
    top: 7px;
}

/*style the items (options), including the selected item:*/

.select-itemsform div, .select-selectedform {
    /* color: #7D7EE8; */
    padding: 5px;
    border: 0;
    /* border-bottom: 3px solid #7D7EE8 !important; */
    cursor: pointer;
    user-select: none;
    padding-left: 5px;
}

@media(max-width: 600px) {
    .select-itemsform div, .select-selectedform {
        padding-top: 14px;
        font-size: 10px;
    }
}

/*style items (options):*/

.select-itemsform {
    position: absolute;
    background-color: white;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
    font-size: 10px;
    height: 150px;
    overflow-y: auto;
}

@media(max-width: 600px) {
    .select-itemsform {
        font-size: 8px;
    }
}

/*hide the items when the select box is closed:*/

.select-hide {
    display: none;
}

.select-itemsform div:hover, .same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
}

.graycolor {
    color: #080808;
    font-weight: normal;
}

.marginbottomtext {
    margin-bottom: 0px;
}

.marginbottomtext1 {
    margin-bottom: 30px;
}

@media(max-width:600px) {
    .marginbottomtext1 {
        margin-bottom: 0px;
    }
}

.bold {
    font-weight: bolder;
}

.sizetitleform {
    font-size: 1.7rem !important;
}

.sizeformtext {
    font-size: 1.2rem !important;
}

@media(max-width:600px) {
    .sizetitleform {
        font-size: 1rem !important;
    }
    .sizeformtext {
        font-size: 0.8rem !important;
    }
    .marginbottomtitle {
        margin-bottom: 10px !important;
    }
}

@media only screen and (max-width: 900px) and (max-height: 400px) and (orientation: landscape) {
    .popuptext {
        overflow-y: auto;
    }
    .popuptext {
        padding-top: 15em;
    }
    .popup-inner {
        max-width: 500px
    }
    .popuptext h3 {
        font-size: 0.8em;
    }
    .popuptext p {
        font-size: 0.7em;
    }
    input.blueinputclass::placeholder {
        font-size: 12px;
        font-family: Comfortaa, cursive
    }
    .blueinputclass {
        font-size: 12px;
    }
    .select-itemsform div, .select-selectedform {
        font-size: 12px;
    }
}

.positionproject {
    width: 100%;
    height: 75vh;
    display: flex;
    justify-content: center;
}

@media(min-width:1500px) {
    .positionproject {
        height: 60vh;
    }
}

/* end overlay form design css */

/* start project demo section  */

.projectvideo {
    width: 100%;
    height: 100%;
}

.projectimgwidth {
    width: 10vw;
    height: 12vw;
    box-shadow: 0 0 20px 0 grey;
    border-radius: 10px;
}

@media(max-width:600px) {
    .projectimgwidth {
        width: 20vw;
        height: 23vw;
    }
}

.flexprojectimg {
    display: flex;
    justify-content: center;
    margin-top: -4em;
}

@media(max-width:600px) {
    .flexprojectimg {
        margin-top: -2.2em;
    }
}

.marginprojectimg {
    margin: 0.7em;
}

.mainproject {
    position: relative;
}

#projectname1 {
    /* display: block; */
    height: auto;
}

.overlayimg1 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 1;
    transition: .5s ease;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    cursor: pointer;
}

.mainproject:hover .overlayimg1 {
    opacity: 0;
}

.projecttext1 {
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    font-weight: bold;
    color: white;
    font-size: 15px;
}

@media(max-width:600px) {
    .projecttext1 {
        font-size: 12px;
        word-break: break-all;
        line-height: 1.1;
    }
}

.flexdemoproject {
    display: flex;
    justify-content: center;
}

.positionprenext {
    display: flex;
    justify-content: center;
    /* margin-top: -3em; */
    align-items: center;
}

.marginlefticon {
    margin-left: 2em;
}

.marginrighticon {
    margin-right: 2em;
}

@media(max-width:600px) {
    .marginlefticon {
        margin-left: 1em;
    }
    .marginrighticon {
        margin-right: 1em;
    }
}

/* end project demo section  */

* {
    user-select: none;
}

.loaderregisterbtn {
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid #3498db;
    width: 16px;
    height: 16px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    margin-left: 30px;
}

/* For portrait layouts only ipad */

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    .popup-inner {
        max-width: 700px;
    }
    .widthicon {
        width: 20vw;
    }
    .endalign {
        text-align: center;
    }
}

.flexprojectimg>* {
    display: none;
}

.flexprojectimg>*:nth-of-type(1) {
    display: flex;
}

.flexprojectimg>*:nth-of-type(2) {
    display: flex;
}

.flexprojectimg>*:nth-of-type(3) {
    display: flex;
}

.flexprojectimg>*:nth-of-type(4) {
    display: flex;
}

.flexprojectimg>*:nth-of-type(5) {
    display: flex;
}

@media(max-width:600px) {
    .flexprojectimg>*:nth-of-type(3) {
        display: none;
    }
    .flexprojectimg>*:nth-of-type(4) {
        display: none;
    }
    .flexprojectimg>*:nth-of-type(5) {
        display: none;
    }
}

.shadowicon {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    border-radius: 50%;
}

@media only screen and (max-width: 900px) and (max-height: 400px) and (orientation: landscape) {
    .text-sm-left {
        text-align: left !important
    }
    .calendar {
        height: 60%;
    }
    .margintoplandscape {
        margin-top: 50px;
    }
    .featurecenterimg {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }
    footer {
        padding-top: 70px;
    }
    .hrmargin1 {
        margin-bottom: 20px;
    }
    .hrmargin2 {
        margin-top: 0px;
    }
    .endalign {
        text-align: center;
    }
    .heightdescription {
        height: 120px;
    }
    .widthimgslide {
        width: 330px;
        display: flex;
        margin: auto;
    }
    .melzotitle {
        font-size: 25px;
    }
    .melzoheading {
        font-size: 35px;
    }
    .size-text {
        font-size: 15px;
        margin: 1rem 0;
    }
    .extramove:before {
        transform: translateX(-98%) !important;
    }
    .movelineafter:after {
        transform: translateX(-10px) !important;
    }
    .projecttext1 {
        font-size: 10px;
    }
    .projectimgwidth {
        width: 20vw;
    }
    .our--clients6 img {
        padding: 10px;
    }
}

/* For portrait layouts only ipad */

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    .flexcontainer {
        font-size: 18px;
    }
    .our--clients img {
        padding: 5px;
    }
    .flexprojectimg>*:nth-of-type(4) {
        display: none;
    }
    .flexprojectimg>*:nth-of-type(5) {
        display: none;
    }
    .projectimgwidth {
        width: 20vw;
        height: 18vw;
    }
}

/* ipad prop landscape*/

.privacylink {
    text-decoration: underline;
    font-size: 12px;
}

.sizecondition {
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
}